<template>
  <div
    class="relative w-full scrollable-menu-wrapper"
    :class="{
      'flex justify-center items-center': hasScroll
    }"
  >
    <template v-if="$slots.iconLeft">
      <slot
        v-if="canScrollLeft"
        name="iconLeft"
        :on-scroll-icon-click="scrollToLeft"
      />
    </template>
    <template v-else>
      <div
        v-if="canScrollLeft"
        class="absolute w-20 h-10 flex items-center justify-center cursor-pointer z-100 scroll-button"
        :class="[
          stylingOptions.buttonsTransparent
            ? 'bg-transparent'
            : 'icon-wrapper-left',
          stylingOptions.offsetLeftClasses || '-top-2 -left-2'
        ]"
        @click="scrollToLeft"
      >
        <icon-chevron-right class="rotate-180 transform" />
      </div>
    </template>
    <div
      ref="menu"
      class="scrollable-menu flex whitespace-nowrap overflow-scroll"
    >
      <slot />
    </div>
    <template v-if="$slots.iconRight">
      <slot
        v-if="canScrollRight"
        name="iconRight"
        :on-scroll-icon-click="scrollToRight"
      />
    </template>
    <template v-else>
      <div
        v-if="canScrollRight"
        class="absolute w-20 h-10 flex items-center justify-center cursor-pointer scroll-button"
        :class="[
          stylingOptions.buttonsTransparent
            ? 'bg-transparent'
            : 'icon-wrapper-right',
          stylingOptions.offsetRightClasses || '-top-2 -right-2'
        ]"
        @click="scrollToRight"
      >
        <icon-chevron-right />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onBeforeUnmount, ref } from "vue";
import type { PropType } from "vue";
import isNull from "lodash/isNull";

export interface LfScrollableMenuStylingOptions {
  offsetLeftClasses?: string;
  offsetRightClasses?: string;
  buttonsTransparent?: boolean;
}

const props = defineProps({
  scrollLength: {
    type: Number,
    default: 100
  },
  stylingOptions: {
    type: Object as PropType<LfScrollableMenuStylingOptions>,
    default: () => ({
      offsetRightClasses: "",
      offsetLeftClasses: "",
      buttonsTransparent: false
    })
  }
});

const menu = ref<HTMLElement | null>(null);
// couldn't use element ref for scroll attributes - not reactive
const clientWidth = ref<number | null>(null);
const scrollWidth = ref<number | null>(null);
const scrollLeft = ref<number | null>(null);

const PIXELS_GAP_FOR_HIDING_RIGHT_CHEVRON = 2;

const setScrollRefs = (entry: ResizeObserverEntry | Event) => {
  clientWidth.value = (entry.target as HTMLElement).clientWidth;
  scrollWidth.value = (entry.target as HTMLElement).scrollWidth;
  scrollLeft.value = (entry.target as HTMLElement).scrollLeft;
};

const hasScroll = computed(() => {
  if (isNull(clientWidth.value) || isNull(scrollWidth.value)) {
    return false;
  }
  return clientWidth.value < scrollWidth.value;
});

const canScrollLeft = computed(() => !!scrollLeft.value);
const canScrollRight = computed(() => {
  if (
    isNull(clientWidth.value) ||
    isNull(scrollWidth.value) ||
    isNull(scrollLeft.value)
  ) {
    return false;
  }
  return (
    clientWidth.value + scrollLeft.value + PIXELS_GAP_FOR_HIDING_RIGHT_CHEVRON <
    scrollWidth.value
  );
});

const scrollToLeft = () => {
  if (isNull(menu.value)) {
    return;
  }
  menu.value.classList.add("smooth-scroll");
  menu.value.scrollLeft -= props.scrollLength;
};

const scrollToRight = () => {
  if (isNull(menu.value)) {
    return;
  }
  menu.value.classList.add("smooth-scroll");
  menu.value.scrollLeft += props.scrollLength;
};

const resizeObserver = new ResizeObserver((entries) => {
  setTimeout(() => {
    const [element] = entries;
    if (element.target) {
      setScrollRefs(element);
    }
  }, 0);
});

onMounted(() => {
  if (isNull(menu.value)) {
    return;
  }
  resizeObserver.observe(menu.value);
  menu.value.addEventListener("scroll", setScrollRefs);
});

onBeforeUnmount(() => {
  if (isNull(menu.value)) {
    return;
  }
  resizeObserver.disconnect();
  menu.value.removeEventListener("scroll", setScrollRefs);
});

defineExpose({ menu });
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable-menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable-menu {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.icon-wrapper-right {
  background: linear-gradient(
    270deg,
    #f1f4f7 78.09%,
    rgba(255, 255, 255, 0) 100%
  );
}

.icon-wrapper-left {
  background: linear-gradient(
    90deg,
    #f1f4f7 78.09%,
    rgba(255, 255, 255, 0) 100%
  );
}

.top-left-0 div:first-child {
  left: 0;
  top: 0;
}
.top-right-0 div:last-child {
  right: 0;
  top: 0;
}
</style>
